import { useEffect, useState } from 'react'
import FormParams from '~/src/types/forms/FormParams'
import * as yup from 'yup'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { CustomerProfileFormInterface } from '~/src/types/forms/CustomerProfileFormInterface'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import ValidatorUtils from '~/src/utils/ValidatorUtils'

const useCustomerProfileForm = (): FormParams<CustomerProfileFormInterface> => {
  const customer = useEoValue(CUSTOMER_STATE)
  const { t } = useTranslations()
  const [ initialValues, setInitialValues ] = useState<CustomerProfileFormInterface>({
    email: customer?.email || '',
    firstName: customer?.firstName || '',
    lastName: customer?.lastName || '',
    phone: (customer?.phone && ValidatorUtils.isPhoneNumberValid(customer?.phone)) ? customer.phone : ''
  })
  const validationSchema = yup.object({
    email: yup
      .string()
      .required(t('errors.emailRequired'))
      .email(t('errors.emailInvalid')),
    firstName: yup
      .string()
      .required(t('errors.firstNameRequired')),
    lastName: yup
      .string()
      .required(t('errors.lastNameRequired')),
    phone: yup
      .string()
      .required(t('errors.phoneRequired'))
      .min(5)
      .test('isNumberValid', t('errors.phoneWrongFormat'), ValidatorUtils.isPhoneNumberValid)
  })

  useEffect(() => {
    setInitialValues({
      email: customer?.email || '',
      firstName: customer?.firstName || '',
      lastName: customer?.lastName || '',
      phone: (customer?.phone && ValidatorUtils.isPhoneNumberValid(customer?.phone)) ? customer.phone : ''
    })
  }, [ customer ])

  return { initialValues, validationSchema }
}

export default useCustomerProfileForm
